import { api } from '.';


// -----------------------------------------------

export const { HOST } = api;

export const endpoints = {
  csrf: path('/csrf-token'),
  logout: path('/api/v1/users/logout'),
  login: path('/api/v1/user/login'),
  getOTP: path('/api/v1/users/auth'),
  resendOTP: path('/api/v1/users/auth/resend'),
  verifyOTP: path('/api/v1/users/auth/verify'),
  users: {
    root: path('/api/v1/admin/users'),
    find: path('/api/v1/users?'),
    fetch: path('/api/v1/users/fetch'),
    track: path('/api/v1/users/track'),
    acceptInvite: path('/api/v1/users/invite/accept'),
    validateInvite: path('/api/v1/users/invite/validate'),
  },
  storeDeviceToken: path('/api/v1/users/device-token'),
  ticket: {
    getGroups: path('/api/v1/groups/find'),
    create: path('/api/v1/tickets'),
    update: path('/api/v1/tickets/update'),
    getDashboard: path('/api/v1/tickets/dashboard'),
    findMultiple: path('/api/v1/tickets/find'),
    getETRS: path('/api/v1/tickets/etr'),
    getExtendETR: path('/api/v1/tickets/etr/extend'),
    extendETR: path('/api/v1/tickets/etr/extend'),
    uploadAttachments: path('/api/v1/tickets/attachments'),
    validateStatusUrl: path('/api/v1/ticket/share/validate'),
    submitIssueFeedback: path('/api/v1/ticket/share/feedback'),
    activity: path('/api/v1/ticket/history'),
    transfer: path('/api/v1/tickets/transfer'),
  },
  task: {
    get: path('/api/v1/tasks/filter'),
    create: path('/api/v1/tasks'),
    delete: path('/api/v1/tasks'),
    update: path('/api/v1/tasks'),
    search: path('/api/v1/tasks/find'),
    updateStatus: path('/api/v1/tasks/status'),
    getDashboard: path('/api/v1/tasks/dashboard'),
    permissions: path('/api/v1/tasks/permission'),
    settings: path('/api/v1/tasks/settings'),
    activity: path('/api/v1/tasks/activity'),
    stats: path('/api/v1/tasks/stats'),
    checklist: {
      find: path('/api/v1/tasks/checklist/find'),
      create: path('/api/v1/tasks/checklist'),
      createbulk: path('/api/v1/tasks/checklist/bulk'),
      history: path('/api/v1/tasks/checklist/history/'),
    },
    checklisttags: {
      find: path('/api/v1/tasks/checklist/tags/find'),
      tagchecklist: path('/api/v1/tasks/checklist/tags'),
      deleteTag: path('/api/v1/tasks/checklist/tags/'),
    },
    checkpoint: {
      find: path('/api/v1/tasks/checkpoints/find'),
      create: path('/api/v1/tasks/checkpoints'),
      history: path('/api/v1/tasks/checkpoints/history/'),
    },
    tags: {
      find: path('/api/v1/tasks/tags/find'),
      create: path('/api/v1/tasks/tags'),
    },
    category: {
      find: path('/api/v1/tasks/checklist/category/find'),
      create: path('/api/v1/tasks/checklist/category'),
    },
    asset: {
      find: path('/api/v1/tasks/assets/find'),
      create: path('/api/v1/tasks/assets'),
      edit: path('/api/v1/tasks/assets/edit'),
      deActivate: path('/api/v1/tasks/assets'),
      createBulkAssets: path('/api/v1/tasks/assets/bulk'),
      findconfig: path('/api/v1/tasks/config/find'),
      config: path('/api/v1/tasks/config')
    },
    assetTask: {
      find: path('/api/v1/tasks/assets/jobs/find'),
      create: path('/api/v1/tasks/assets/jobs'),
      validate: path('/api/v1/tasks/assets/jobs/validate/'),
      status: path('/api/v1/tasks/assets/jobs/status'),
      history: path('/api/v1/tasks/assets/jobs/history/'),
    },
    assetclass: {
      find: path('/api/v1/tasks/assets/class/find'),
      create: path('/api/v1/tasks/assets/class'),
    },
  },
  admin: {
    dashboard: path('/api/v1/admin'),
    sendEmail: path('/api/v1/admin/users/invite'),
    sendBulkEmails: path('/api/v1/admin/users/invite/bulk'),
    addEtrSettings: path('/api/v1/admin/facility/config'),
    getEtrSettings: path('/api/v1/admin/facility/config/find'),
    getAddresses: path('/api/v1/facility/address/find'),
    addAddresses: path('/api/v1/admin/facility/address'),
    reports: path('/api/v1/admin/report/tickets'),
    reportTile: path('/api/v1/admin/report/tickets/tile'),
  },
  group: {
    create: path('/api/v1/admin/groups'),
    edit: path('/api/v1/admin/groups'),
    getGroups: path('/api/v1/groups/find'),
    addUsers: path('/api/v1/admin/usergroups'),
    findMembersInGroup: path('/api/v1/usergroups/find'),
    deleteMember: path('/api/v1/usergroups'),
    schedule: path('/api/v1/admin/groups/schedule'),
    createSchedule: path('/api/v1/admin/groups/schedule/config'),
    updateSchedule: path('/api/v1/admin/groups/schedule/config'),
    deleteSchedule: path('/api/v1/admin/groups/schedule'),
    scheduleUpdate: path('/api/v1/admin/groups/schedule'),
    scheduleFind: path('/api/v1/groups/find/schedule'),
  },
  user: {
    user: path('/api/v1/users'),
    searchUsers: path('/api/v1/users?search'),
    findUsers: path('/api/v1/users/find'),
    createUser: path('/api/v1/admin/users'),
    findPermissions: path('/api/v1/admin/users/flapx/permission/find'),
    updatePermissions: path('/api/v1/admin/users/flapx/permission'),
    availability: path('/api/v1/ua/find'),
    updateAvailability: path('/api/v1/ua'),
  },
  findAgreement: path('/api/v1/agreements/find'),
  agreement: path('/api/v1/agreements'),
  facility: {
    create: path('/api/v1/admin/facility'),
    find: path('/api/v1/facility/find'),
    findAddress: path('/api/v1/facility/address/find'),
    stats: path('/api/v1/admin/stats/facility'),
    getTicketStats: path('/api/v1/admin/stats/tickets')
  },
  zapx: {
    patient: {
      find: path('/api/v1/zapx/patient_profile/find'),
      create: path('/api/v1/zapx/patient_profile'),
      update: path('/api/v1/zapx/patient_profile'),
      search: path('/api/v1/zapx/patient_profile/search'),
      clinical_param: {
        find: path('/api/v1/zapx/patient_profile/clinical_param/find'),
        create: path('/api/v1/zapx/patient_profile/clinical_param'),
        update: path('/api/v1/zapx/patient_profile/clinical_param'),
        delete: path('/api/v1/zapx/patient_profile/clinical_param'),
      },
      diagnosis: {
        find: path('/api/v1/zapx/patient_profile/diagnosis/find'),
        create: path('/api/v1/zapx/patient_profile/diagnosis'),
        delete: path('/api/v1/zapx/patient_profile/diagnosis'),
      },
      comorbidity: {
        find: path('/api/v1/zapx/patient_profile/comorbidity/find'),
        create: path('/api/v1/zapx/patient_profile/comorbidity'),
        delete: path('/api/v1/zapx/patient_profile/comorbidity'),
      },
      assessment: {
        create: path('/api/v1/zapx/patient/assessment'),
        find: path('/api/v1/zapx/patient/assessment'),
        update: path('/api/v1/zapx/patient/assessment'),
        delete: path('/api/v1/zapx/patient/assessment'),
      },
     
    },
    foodMenu: {
      find: path('/api/v1/zapx/food_menu/find'),
      create: path('/api/v1/zapx/food_menu'),
      update: path('/api/v1/zapx/food_menu'),
      delete: path('/api/v1/zapx/food_menu'),
      items: {
        find: path('/api/v1/zapx/food_menu/items/find'),
        create: path('/api/v1/zapx/food_menu/items'),
        update: path('/api/v1/zapx/food_menu/items'),
        delete: path('/api/v1/zapx/food_menu/items'),
      }
    },
    mastermenu: {
      find: path('/api/v1/zapx/diet_chart_item/find'),
      create: path('/api/v1/zapx/diet_chart_item'),
      findNutritionLabel: path('/api/v1/zapx/master/nutrition/find'),
      findNutritionData: path('/api/v1/zapx/diet_chart_item/info')
    },
    allergy: {
      find: path('/api/v1/zapx/allergy/find'),
      create: path('/api/v1/zapx/allergy'),
      findAllergyItem: path('/api/v1/zapx/allergy/items'),
    },
    mealBatches: {
      find: path('/api/v1/zapx/kms/batch'),
      details: path('/api/v1/zapx/km/batch'),
      start: path('/api/v1/zapx/km/batch/start'),
      updateStatus: path('/api/v1/zapx/km/batch/status'),
      assignSteward: path('/api/v1/zapx/km/batch/assign'),
      mealStatus: path('/api/v1/zapx/batch/profile/status'),
    },
    ds: {
      findOrders: path('/api/v1/zapx/kms/batch/profile'),
      scanKitchenQR: path('/api/v1/zapx/steward/scanKitchen'),
      updateOrderStatus: path('/api/v1/zapx/steward/order/update'),
      scanOrderQR: path('/api/v1/zapx/steward/scanOrder'),
      validateOrder: path('/api/v1/zapx/steward/compare'),

      findAdHocOrders: path('/api/v1/zapx/orders/find'),
    },
    ingredient: {
      find: path('/api/v1/zapx/ingredient/find'),
      create: path('/api/v1/zapx/ingredient'),
      item: {
        find: path('/api/v1/zapx/item/ingredient/find'),
        create: path('/api/v1/zapx/item/ingredient'),
      }
    },
    manageMeals: {
      find: path('/api/v1/zapx/orders/find/items'),
      order: path('/api/v1/zapx/orders'),
      verifyPayment: path('/api/v1/zapx/orders/payment'),
      orderHistory: path('/api/v1/zapx/orders/find/history'),
      findStatus: path('/api/v1/zapx/orders/find/status'),
      findOrder: path('/api/v1/zapx/orders/find'),
      updateOrder: path('/api/v1/zapx/orders/update'),
      rejectOrder: path('/api/v1/zapx/orders/reject')
    },
    comorbidities: {
      create: path('/api/v1/zapx/config/comorbidity'),
      find: path('/api/v1/zapx/config/comorbidity/find'),
      delete: path('/api/v1/zapx/config/comorbidity'),
      update: path('/api/v1/zapx/config/comorbidity'),
    },
    feedtype: {
      create: path('/api/v1/zapx/config/tof'),
      find: path('/api/v1/zapx/config/tof/find'),
      delete: path('/api/v1/zapx/config/tof'),
      update: path('/api/v1/zapx/config/tof'),
    },
    patientcategory: {
      create: path('/api/v1/zapx/config/patient_cat'),
      find: path('/api/v1/zapx/config/patient_cat/find'),
      delete: path('/api/v1/zapx/config/patient_cat'),
      update: path('/api/v1/zapx/config/patient_cat'),
    },
    clinicalparameter: {
      create: path('/api/v1/zapx/config/clinical_param'),
      find: path('/api/v1/zapx/config/clinical_param/find'),
      delete: path('/api/v1/zapx/config/clinical_param'),
      update: path('/api/v1/zapx/config/clinical_param'),
    },
    diagnosis: {
      create: path('/api/v1/zapx/config/diagnosis'),
      find: path('/api/v1/zapx/config/diagnosis/find'),
      delete: path('/api/v1/zapx/config/diagnosis'),
      update: path('/api/v1/zapx/config/diagnosis'),
      createBulkDiagnosis: path('/api/v1/zapx/config/diagnosis/bulk'),
    },
    assessment: {
      create: path('/api/v1/zapx/config/assessment'),
      find: path('/api/v1/zapx/config/assessment/find'),
      delete: path('/api/v1/zapx/config/assessment'),
      update: path('/api/v1/zapx/config/assessment'),
    },
    dietary_pref: {
      create: path('/api/v1/zapx/config/dietary_pref'),
      find: path('/api/v1/zapx/config/dietary_pref/find'),
      delete: path('/api/v1/zapx/config/dietary_pref'),
      update: path('/api/v1/zapx/config/dietary_pref'),
    },
  },
  kitchens:{
    find: path('/api/v1/kitchens/find')
  }
}

// -----------------------------------------------


function path(endpoint) {
  return `${HOST}${endpoint}`;
}