import React, { createContext, useReducer, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useLocalStorage from '../hooks/useLocalStorage';

// Initial state for the entire application,
const initialState = {
  zapx: {
    masterMenu: {
      masterMenuItems: [],
      currentItem: null,
      currentNutritionData: null,
      menuFormType: null,   // "EDIT" or "CREATE"
      nutritionLabelsResponse: [],
      mappedNutritionLabels: [],
    },
    cafeteriaMasterMenu: {
      masterMenuItems: [],
      currentItem: null,
      currentIngredientData: null
    },
    dietChartMenu: {
      dietChartMenuItems: [],
      currentFoodItem: null,
      dietChartData: []
    },
    allergyMenu: {
      allergyMenuItems: [],
      currentFoodItem: null,
      allergyItemsList: []
    },
    cafeteriaMenu: {
      cafeteriaMenuItems: [],
      currentFoodItem: null,
      cafeteriaItemsList: []
    },
    patient: {
      patientDetails: {},
      patients: [],
      address: [],
      dietMenu: [],
      allergyMenu: [],
      location: '',
      patientcategory: [],
    },
    config: {
      feedType: [],
      patientcategory: [],
    },
    mealService: {
      scanInfo: {},
      cart: [],
      orderResponse: {},
      verifiedPaymentResponse: {},
      dialogOpen: false,
      orderDetail: {},
      orderStatus: []
    },
    realTimeOrders: {
      kitchens: [],
      selectedKitchen: {},
      newOrders: [],
    },

    // ... other states
  },
};

// Action types
export const actionTypes = {
  // Patient action types
  SET_PATIENT_DETAILS: 'SET_PATIENT_DETAILS',
  SET_PATIENTS: 'SET_PATIENTS',
  SET_PATIENT_ADDRESS: 'SET_PATIENT_ADDRESS',
  SET_PATIENT_DIET_MENU: 'SET_PATIENT_DIET_MENU',
  SET_PATIENT_ALLERGY_MENU: 'SET_PATIENT_ALLERGY_MENU',
  SET_PATIENT_LOCATION: 'SET_PATIENT_LOCATION',

  // Set Config action types
  SET_PATIENTS_CATEGORY: 'SET_PATIENTS_CATEGORY',
  SET_FEED_TYPE: 'SET_FEED_TYPE',
  CURRENT_DIET_TYPE_CATEGORY: 'CURRENT_DIET_TYPE_CATEGORY',
  SET_DIET_TYPE_CATEGORIES: 'SET_DIET_TYPE_CATEGORIES',
  SET_CATEGORY_TO_EDIT: 'SET_CATEGORY_TO_EDIT',
  // Master menu type
  SET_MASTER_MENU_ITEMS: 'SET_MASTER_MENU_ITEMS',
  SET_CURRENT_ITEM: 'SET_CURRENT_ITEM',
  SET_NUTRITION_DATA: 'SET_NUTRITION_DATA',
  SET_MENU_FORM_TYPE: 'SET_MENU_FORM_TYPE',
  SET_NUTRITION_LABELS: 'SET_NUTRITION_LABELS',
  SET_NUTRITION_LABELS_RESPONSE: 'SET_NUTRITION_LABELS_RESPONSE',
  // Cafeteria Master Menu action types
  SET_CAFETERIA_MASTER_MENU_ITEMS: 'SET_CAFETERIA_MASTER_MENU_ITEMS',
  SET_CAFETERIA_CURRENT_ITEM: 'SET_CAFETERIA_CURRENT_ITEM',
  SET_CAFETERIA_CURRENT_INGREDIENT_DATA: 'SET_CAFETERIA_CURRENT_INGREDIENT_DATA',
  // Diet menu action types
  SET_DIET_CHART_MENU_ITEMS: 'SET_DIET_CHART_MENU_ITEMS',
  SET_CURRENT_FOOD_ITEM: 'SET_CURRENT_FOOD_ITEM',
  SET_DIET_CHART_DATA: 'SET_DIET_CHART_DATA',
  // Allergy menu action types
  SET_ALLERGY_MENU_ITEMS: 'SET_ALLERGY_MENU_ITEMS',
  SET_CURRENT_ALLERGY_ITEM: 'SET_CURRENT_ALLERGY_ITEM',
  SET_ALLERGY_ITEMS_LIST: 'SET_ALLERGY_ITEMS_LIST',
  // Cafeteria menu action types
  SET_CAFETERIA_MENU_ITEMS: 'SET_CAFETERIA_MENU_ITEMS',
  SET_CURRENT_CAFETERIA_ITEM: 'SET_CURRENT_CAFETERIA_ITEM',
  SET_CAFETERIA_ITEMS_LIST: 'SET_CAFETERIA_ITEMS_LIST',
  // meal service
  SET_MEAL_SERVICE_SCAN_INFO: 'SET_MEAL_SERVICE_SCAN_INFO',
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  UPDATE_ITEM_QUANTITY: 'UPDATE_ITEM_QUANTITY',
  CLEAR_CART: 'CLEAR_CART',
  SET_ORDER_RESPONSE: 'SET_ORDER_RESPONSE',
  CLEAR_ORDER_RESPONSE: 'CLEAR_ORDER_RESPONSE',
  SET_VERIFIED_PAYMENT_RESPONSE: 'SET_VERIFIED_PAYMENT_RESPONSE',
  OPEN_DIALOG: 'OPEN_DIALOG',
  CLOSE_DIALOG: 'CLOSE_DIALOG',
  SET_ORDER_DETAIL: 'SET_ORDER_DETAIL',
  SET_ORDER_STATUS: 'SET_ORDER_STATUS',
  // real time orders
  SET_KITCHENS: 'SET_KITCHENS',
  SET_SELECTED_KITCHEN: 'SET_SELECTED_KITCHEN',
  SET_NEW_ORDERS: 'SET_NEW_ORDERS',
  CLEAR_SELECTED_KITCHEN: 'CLEAR_SELECTED_KITCHEN',
  REMOVE_ORDER: 'REMOVE_ORDER'
};

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    // patient
    case actionTypes.SET_PATIENT_DETAILS:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          patient: {
            ...state.zapx.patient,
            patientDetails: action.payload,
          },
        },
      };
    case actionTypes.SET_PATIENTS:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          patient: {
            ...state.zapx.patient,
            patients: action.payload,
          },
        },
      };
    case actionTypes.SET_PATIENT_ADDRESS:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          patient: {
            ...state.zapx.patient,
            address: action.payload,
          },
        },
      };

    case actionTypes.SET_PATIENT_DIET_MENU:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          patient: {
            ...state.zapx.patient,
            dietMenu: action.payload,
          },
        },
      };

    case actionTypes.SET_PATIENT_ALLERGY_MENU:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          patient: {
            ...state.zapx.patient,
            allergyMenu: action.payload,
          },
        },
      };
    case actionTypes.SET_PATIENT_LOCATION:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          patient: {
            ...state.zapx.patient,
            location: action.payload,
          },
        },
      };
    case actionTypes.SET_PATIENTS_CATEGORY:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          config: {
            ...state.zapx.config,
            patientcategory: action.payload,
          },
        },
      };

      case actionTypes.SET_FEED_TYPE: 
      return {
        ...state,
        zapx: {
          ...state.zapx,
          config: {
            ...state.zapx.config,
            feedType: action.payload,
          },
        },
      };
      case actionTypes.CURRENT_DIET_TYPE_CATEGORY:
        return {
         ...state,
          zapx: {
           ...state.zapx,
            config: {
             ...state.zapx.config,
              currentDietTypeCategory: action.payload,
            },
          },
        };
        case actionTypes.SET_DIET_TYPE_CATEGORIES:
          return {
           ...state,
            zapx: {
             ...state.zapx,
              config: {
               ...state.zapx.config,
                dietTypeCategories: action.payload,
              },
            },
          };
          case actionTypes.SET_CATEGORY_TO_EDIT:
            return {
             ...state,
              zapx: {
               ...state.zapx,
                config: {
                 ...state.zapx.config,
                  categoryToEdit: action.payload,
                },
              },
            };

    // Master Menu Item

    // Cafeteria

    // Master Menu Item
    case actionTypes.SET_MASTER_MENU_ITEMS:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          masterMenu: { ...state.zapx.masterMenu, masterMenuItems: action.payload },
        },
      };
    case actionTypes.SET_CURRENT_ITEM:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          masterMenu: { ...state.zapx.masterMenu, currentItem: action.payload },
        },
      };

    case actionTypes.SET_NUTRITION_DATA:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          masterMenu: {
            ...state.zapx.masterMenu,
            currentNutritionData: action.payload,
          },
        },
      };

    case actionTypes.SET_MENU_FORM_TYPE:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          masterMenu: {
            ...state.zapx.masterMenu,
            menuFormType: action.payload,
          },
        },
      };
    case actionTypes.SET_NUTRITION_LABELS:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          masterMenu: {
            ...state.zapx.masterMenu,
            mappedNutritionLabels: action.payload,
          },
        },
      };
    case actionTypes.SET_NUTRITION_LABELS_RESPONSE:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          masterMenu: {
            ...state.zapx.masterMenu,
            nutritionLabelsResponse: action.payload,
          },
        },
      };

    // Cafeteria Master Menu cases
    case actionTypes.SET_CAFETERIA_MASTER_MENU_ITEMS:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          cafeteriaMasterMenu: {
            ...state.zapx.cafeteriaMasterMenu,
            masterMenuItems: action.payload,
          },
        },
      };
    case actionTypes.SET_CAFETERIA_CURRENT_ITEM:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          cafeteriaMasterMenu: {
            ...state.zapx.cafeteriaMasterMenu,
            currentItem: action.payload,
          },
        },
      };

    case actionTypes.SET_CAFETERIA_CURRENT_INGREDIENT_DATA:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          cafeteriaMasterMenu: {
            ...state.zapx.cafeteriaMasterMenu,
            currentIngredientData: action.payload,
          },
        },
      };

    // Dietchart Menu
    case actionTypes.SET_DIET_CHART_MENU_ITEMS:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          dietChartMenu: {
            ...state.zapx.dietChartMenu,
            dietChartMenuItems: action.payload,
          },
        },
      };

    case actionTypes.SET_CURRENT_FOOD_ITEM:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          dietChartMenu: {
            ...state.zapx.dietChartMenu,
            currentFoodItem: action.payload,
          },
        },
      };
    case actionTypes.SET_DIET_CHART_DATA:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          dietChartMenu: {
            ...state.zapx.dietChartMenu,
            dietChartData: action.payload,
          },
        },
      };

    // Allergy menu cases
    case actionTypes.SET_ALLERGY_MENU_ITEMS:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          allergyMenu: {
            ...state.zapx.allergyMenu,
            allergyMenuItems: action.payload,
          },
        },
      };
    case actionTypes.SET_CURRENT_ALLERGY_ITEM:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          allergyMenu: {
            ...state.zapx.allergyMenu,
            currentFoodItem: action.payload,
          },
        },
      };
    case actionTypes.SET_ALLERGY_ITEMS_LIST:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          allergyMenu: {
            ...state.zapx.allergyMenu,
            allergyItemsList: action.payload,
          },
        },
      };

    // Cafeteria menu cases
    case actionTypes.SET_CAFETERIA_MENU_ITEMS:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          cafeteriaMenu: {
            ...state.zapx.cafeteriaMenu,
            cafeteriaMenuItems: action.payload,
          },
        },
      };
    case actionTypes.SET_CURRENT_CAFETERIA_ITEM:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          cafeteriaMenu: {
            ...state.zapx.cafeteriaMenu,
            currentFoodItem: action.payload,
          },
        },
      };
    case actionTypes.SET_CAFETERIA_ITEMS_LIST:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          cafeteriaMenu: {
            ...state.zapx.cafeteriaMenu,
            cafeteriaItemsList: action.payload,
          },
        },
      };

    //  meal service
    case actionTypes.SET_MEAL_SERVICE_SCAN_INFO:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            scanInfo: action.payload,
          },
        },
      };
    case actionTypes.ADD_TO_CART:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            // Initialize quantity = 1
            cart: [...state.zapx.mealService.cart, { ...action.payload, quantity: 1 }]
          },
        },
      };
    case actionTypes.REMOVE_FROM_CART:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            cart: state.zapx.mealService.cart.filter(item => item.id !== action.payload.id),
          },
        },
      };
    case actionTypes.UPDATE_ITEM_QUANTITY: {

      const updatedCart = state.zapx.mealService.cart.map(item => {
        if (item.id === action.payload.id) {
          // Ensure item.quantity is a number, default to 1 if not
          const currentQuantity = typeof item.quantity === 'number' ? item.quantity : 1;
          // Ensure action.payload.delta is a number
          const delta = typeof action.payload.delta === 'number' ? action.payload.delta : 0;
          // Calculate new quantity
          const newQuantity = Math.max(1, currentQuantity + delta);

          // console.log(`Updating quantity for item ${item.id}: currentQuantity=${currentQuantity}, delta=${delta}, newQuantity=${newQuantity}`); // Debugging log

          return {
            ...item,
            quantity: newQuantity
          };
        }
        return item;
      });

      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            cart: updatedCart
          },
        },
      };
    }
    case actionTypes.CLEAR_CART:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            cart: [],
          },
        },
      };
    case actionTypes.SET_ORDER_RESPONSE:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            orderResponse: action.payload,
          },
        },
      };
    case actionTypes.CLEAR_ORDER_RESPONSE:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            orderResponse: {}
          }
        }
      };
    case actionTypes.SET_VERIFIED_PAYMENT_RESPONSE:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            verifiedPaymentResponse: action.payload,
          }
        }
      };
    case actionTypes.OPEN_DIALOG:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            dialogOpen: true,
          },
        },
      };
    case actionTypes.CLOSE_DIALOG:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            dialogOpen: false,
          },
        },
      };
    case 'SET_ORDER_DETAIL':
      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            orderDetail: action.payload
          }
        }
      };

    case 'SET_ORDER_STATUS':
      return {
        ...state,
        zapx: {
          ...state.zapx,
          mealService: {
            ...state.zapx.mealService,
            orderStatus: action.payload
          }
        }
      };
    // real time orders related
    case 'SET_KITCHENS':
      return {
        ...state,
        zapx: {
          ...state.zapx,
          realTimeOrders: {
            ...state.zapx.realTimeOrders,
            kitchens: action.payload,
          },
        },
      };
    case 'SET_SELECTED_KITCHEN':
      return {
        ...state,
        zapx: {
          ...state.zapx,
          realTimeOrders: {
            ...state.zapx.realTimeOrders,
            selectedKitchen: action.payload,
          },
        },
      };
    case 'SET_NEW_ORDERS':
      console.log(action.payload)
      return {
        ...state,
        zapx: {
          ...state.zapx,
          realTimeOrders: {
            ...state.zapx.realTimeOrders,
            newOrders: action.payload,
          },
        },
      };
    case actionTypes.REMOVE_ORDER:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          realTimeOrders: {
            ...state.zapx.realTimeOrders,
            newOrders: state.zapx.realTimeOrders.newOrders.filter(order => order.id !== action.payload)
          }
        }
      };

    case actionTypes.CLEAR_SELECTED_KITCHEN:
      return {
        ...state,
        zapx: {
          ...state.zapx,
          realTimeOrders: {
            ...state.zapx.realTimeOrders,
            selectedKitchen: {}
          }
        }
      };


    // ... other cases
    default:
      return state;
  }
};

// Context creation
const AppContext = createContext();

// Provider Component
export const AppProvider = ({ children }) => {
  const [storedValue, setStoredValue] = useLocalStorage('APP_CONTEXT_VALUES', initialState);

  const [state, dispatch] = useReducer(reducer, storedValue);

  // Memoize the context value
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    setStoredValue(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

// Custom Hook
export const useAppContext = () => useContext(AppContext);

// Define propTypes
AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};