//
import PropTypes from 'prop-types';
import { IdleTimerProvider } from 'react-idle-timer';

// hooks
import useUsers from '../hooks/pages/useUsers';
import useAuth from '../hooks/useAuth';
import useLocalStorage from '../hooks/useLocalStorage';
// ------------------------------------------

export function IdleProvider({ children }) {

  const { trackUser } = useUsers();
  const { auth } = useAuth();

  const [, setLastActiveTime] = useLocalStorage('LAST_ACTIVE_TIME', null);

  const onAction = () => {
    // Do something when a user triggers a watched event
    // console.log('onAction: ', event?.type ?? 'Event', ' ', new Date().getTime())
    const eventTime = new Date().getTime();
    setLastActiveTime(eventTime);
    updateUserActivity();
  }

  const updateUserActivity = async () => {
    if (auth && auth.token) {
      await trackUser();
    }
  }

  return (
    <IdleTimerProvider
      throttle={1000 * 60}
      eventsThrottle={1000 * 60}
      onAction={onAction}
      startManually
      timeout={1000 * 30}
    >
      {children}
    </IdleTimerProvider>
  )
}

IdleProvider.propTypes = {
  children: PropTypes.node
};