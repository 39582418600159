
// main scss file
import './assets/scss/main.scss';

import React from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// redux
import { PersistGate } from 'redux-persist/lib/integration/react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { persistor, store } from './redux/store';


import { SettingsProvider } from './contexts/SettingsContext';

import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


const GlobalDndContext = (props) => (
      <DndProvider backend={HTML5Backend} key={1}>
          {/* eslint-disable-next-line react/prop-types */}
        {props.children}
      </DndProvider>
  );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SettingsProvider>
            <BrowserRouter>
                <GlobalDndContext>
                 <App />
                </GlobalDndContext>
            </BrowserRouter>
          </SettingsProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
