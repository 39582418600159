import React, { Suspense, lazy } from 'react';
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { ErrorBoundary } from 'react-error-boundary';

// mui theme
import ThemeProvider from './theme';
// contexts
import { AuthProvider } from './contexts/AuthContext';
import { IdleProvider } from './contexts/IdleProvider';
// hooks
import { useServiceWorker } from './hooks/useServiceWorker.ts';
import { AppProvider } from './contexts/AppContext';
import ErrorFallback from './components/error/ErrorFallback';
// Error Boundary
// Loading Spinner component
import LoadingSpinner from './components/micro/LoadingSpinner';

// Lazy-loaded components
const Router = lazy(() => import('./routes'));
const ScrollToTop = lazy(() => import('./components/micro/ScrollToTop'));
const NotistackProvider = lazy(() => import('./components/others/NotistackProvider'));
const Settings = lazy(() => import('./components/settings'));




// javascript-time-ago initialized
TimeAgo.addDefaultLocale(en);

// -----------------------------------------------

export default function App() {
  // Calling below hook registers service worker
  // eslint-disable-next-line no-unused-vars
  const serviceworker = useServiceWorker();

  return (
    <div>
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback} action={() => { }}>
          <Suspense fallback={<LoadingSpinner />} >
            <NotistackProvider>
              <AuthProvider>
                <IdleProvider>
                  <Settings />
                  <ScrollToTop />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <AppProvider>
                      <Router />
                    </AppProvider>
                  </LocalizationProvider>
                </IdleProvider>
              </AuthProvider>
            </NotistackProvider>
          </Suspense>
        </ErrorBoundary>

      </ThemeProvider>
    </div>
  );
}